import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthConfigRepositoryService, AuthService, BaseflowAuthModule } from 'baseflow-auth';
import { ToastrModule } from 'ngx-toastr';
import {
    AuthInitializerFactory,
    BaseAppInitializerFactory,
    ENVIRONMENT,
    MAP_AUTH_CONFIG,
    SharedModule,
    SpinnerComponent,
} from 'shared';

import buildVersion from '../assets/build-version.json';
import { environment } from '../environments/environment';
import { AppComponent } from './components/app/app.component';
import {
    WizardStepCreateAccountComponent,
    WizardStepCredentialsComponent,
    WizardStepLoggedInComponent,
    WizardStepLoginComponent,
    WizardStepProfileComponent,
    WizardStepTenantInviteComponent,
    WizardStepTenantInviteSuccessComponent,
    WizardStepUpdateSuccessComponent,
} from './components/wizard-step';
import { WizardComponent } from './components/wizard/wizard.component';
import { MapAuthConfigFactory, TranslateLoaderFactory } from './factories';
import { AppSettings } from './models/app-settings.model';
import { AppSettingsService } from './services/app-settings.service';
import { WizardService } from './services/wizard.service';

registerLocaleData(localeNl);

const routes: Routes = [
    { path: 'login', component: AppComponent },
    {
        path: 'tenant-invite',
        component: AppComponent,
        children: [{ path: ':tenantId', component: AppComponent }],
    },
    { path: '**', component: AppComponent },
];

@NgModule({
    declarations: [
        AppComponent,
        WizardComponent,
        WizardStepCreateAccountComponent,
        WizardStepCredentialsComponent,
        WizardStepLoggedInComponent,
        WizardStepLoginComponent,
        WizardStepProfileComponent,
        WizardStepTenantInviteComponent,
        WizardStepTenantInviteSuccessComponent,
        WizardStepUpdateSuccessComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        // NG modules
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule,
        RouterModule.forRoot(routes),
        // Package modules
        SharedModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [],
            },
        }),
        // Application modules
        BaseflowAuthModule,
        // Standalone components
        SpinnerComponent,
    ],
    providers: [
        DatePipe,
        WizardService,
        { provide: ENVIRONMENT, useValue: environment },
        {
            provide: APP_INITIALIZER,
            useFactory: BaseAppInitializerFactory<AppSettings>,
            deps: [TranslateService, AppSettingsService, ENVIRONMENT],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: AuthInitializerFactory,
            deps: [AppSettingsService, AuthConfigRepositoryService, MAP_AUTH_CONFIG, AuthService],
            multi: true,
        },
        {
            provide: MAP_AUTH_CONFIG,
            useValue: MapAuthConfigFactory,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    public constructor(translate: TranslateService) {
        console.log(`Initializing User Portal Web client v${buildVersion.buildVersion}...`);
        translate.use(environment.defaultLocale);
    }
}
